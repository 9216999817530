<template>
  <b-card>
    <b-row>
      <b-col
        md="2"
        sm="4"
        class="my-1"
      >
        <b-form-group
          class="mb-0"
        >
          <label class="d-inline-block text-sm-left mr-50">Afficher</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-table
          striped
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
        >
          <template #cell(etat)="data">
            <b-badge :variant="getVariant(data.value)">
              {{ data.value }}
            </b-badge>
          </template>

          <template #cell(projet)="data">
            <router-link :to="{ name: 'programmes-detail', params: { id: encrypt(data) }}">
              {{ data.item.projet }}
            </router-link>
          </template>
        </b-table>
      </b-col>

      <b-col
        cols="12"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="right"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
  </b-card>

</template>

<script>
import {
  BCard, BTable, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BButton, VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import CryptoJS from 'crypto-js'

const config = require('@/../config')

export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BButton,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      perPage: 10,
      pageOptions: [10, 25, 50],
      totalRows: 1,
      currentPage: 1,
      fields: [
        {
          key: 'projet', label: 'Programme',
        },
        { key: 'etat', label: 'Etat' },
      ],
      items: [],
    }
  },
  mounted() {
    this.list()
  },
  methods: {
    list() {
      const userData = JSON.parse(localStorage.getItem('userData'))

      if (userData.projet) {
        this.$store.dispatch('projets/fetchOne', userData.projet).then(
          projet => {
            this.items = []
            this.items.push({
              value: projet['@id'],
              label: projet.nom,
              id: projet.id,
              projet: projet.nom,
              uri: projet['@id'],
              etat: projet.etat.toUpperCase(),
            })
            this.totalRows = 1
          },
        )
      } else {
        this.$store.dispatch('projets/fetchAll', { page: this.currentPage, limit: this.perPage }).then(
          res => {
            this.items = res.projets
            this.totalRows = res.total
          },
        )
      }
    },
    getVariant(value) {
      if (value.toLowerCase().includes('livraison')) {
        return 'primary'
      } if (value.toLowerCase().includes('termine')) {
        return 'light-danger'
      }
      return 'light-secondary'
    },
    encrypt(data) {
      return CryptoJS.AES.encrypt(data.item.id.toString(), config.crypto_key).toString()
    },
  },
}
</script>
